<template>
<div class="quiz-view">
    <div class="action-selection__content">

        <h1>Hot Quiz</h1>
        <p v-if="question != null">
            {{ question.question }}
        </p>

        <PtySelector style="width: 100%;" v-if="question != null" :options="question.answers.map(e => ({
                id: e.id,
                title: e.text,
                icon: e.icon || 'bx-chevron-right',
            }))" v-model="selectedAnswers" :value="selectedAnswers" iconType="boxicon" block size="small" :gap="1" :multiple="question.is_multiple" />

    </div>
    <PtyNext :steps="0" :current="0" @next="confirm" />
</div>
</template>

    
<script>
import {
    GlobalEventEmitter
} from '@/GlobalEventEmitter';
import {
    chatService
} from '@/rest';
import PtyNext from '../components/PtyNext.vue';
import PtySelector from '../components/PtySelector.vue';
export default {
    name: 'Quiz',
    components: {
        PtyNext,
        PtySelector
    },
    data: () => ({
        selectedAnswers: [],
        question: null,
    }),

    methods: {
        async confirm(){
            
            let answerText = '';

            if(typeof this.selectedAnswers == 'string' || typeof this.selectedAnswers == 'number') {
                answerText = this.question.answers.find(e => e.id == this.selectedAnswers).text;
            } else { // array of ids
                answerText = this.selectedAnswers.map(e => this.question.answers.find(a => a.id == e).text).join(', ');
            }

            const result = await chatService.answerQuestion({
                questionId: this.question.id,
                chatId: this.$route.params.chat_id,
            });

            if(result.status == 200){
                this.$router.push({
                    name: 'Chat',
                    params: this.$route.params
                });

                GlobalEventEmitter.$emit('ws-send', JSON.stringify({
                    chat_id: this.$route.params.chat_id,
                    participant_id: this.$route.params.participantId,
                    text: '🔥 Hot Quiz',
                    metadata: JSON.stringify({
                        type: 'quiz',
                        question_id: this.question.id,
                        answer: answerText,
                        question: this.question.question,
                    })
                }));

                // i have to remind the latest answered question in this chat, so i store the id in localstorage
                localStorage.setItem(`chat_${this.$route.params.chat_id}_last_question`, this.question.id);
            } else {
                // error
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('ChatQuiz.canNotAnswer'),
                    border: 'primary',
                    position: 'top-center'
                });
            }
        }
    },

    async mounted() {
       if(this.$route.params.questions && this.$route.params.questions.length > 0){
            this.question = this.$route.params.questions.shift();
        } else {
            // go to chats
            this.$router.push({
                name: 'Chats'
            });
        }
    },
}
</script>

    
<style scoped>
.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-top: 3rem;
    width: 100%;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

p {
    color: #000;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
}

.quiz-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    background:
        url(../assets/images/illustrations/logos.png) top/130% auto no-repeat,
        linear-gradient(180deg, rgb(210, 251, 253) 16.57%, rgb(255, 255, 255) 53.63%);

}
</style>
